import axios from 'axios';
import { Notice } from 'view-design';
// import md5 from 'js-md5';
import localstorage from '@/lib/localstorage';
import { loginUrl } from '@/lib/api';
import { localStorageKey } from './localStorageKey';

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 15000,
    headers: {
        //
    },
    withCredentials: false, //default 跨域请求时是否需要使用凭证
    responseType: 'json', // default 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
});

//请求拦截
instance.interceptors.request.use(
    config => {
        //console.log('request config ', config);
        let token = localstorage.get(localStorageKey.ACCESS_TOKEN_QILANG);
        if (token && Object.keys(token).length > 0 && config.url != loginUrl) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    error => {
        Promise.error(error);
    }
);

//响应拦截
instance.interceptors.response.use(
    response => {
        // console.log('interceptors response', response);
        if (response.data.errcode !== 0) {
            let errorInfo = response.data.errmsg === null ? '系统内部异常，请联系网站管理员' : response.data.errmsg;
            Notice.error({
                title: '系统提示',
                desc: errorInfo,
                duration: 4,
            });
            if (response.data.errcode == 2000) {
                localStorage.clear();
                // if (process.env.NODE_ENV === 'development') {
                //     window.location.replace('http://localhost:8080');
                // } else {
                //     window.location.replace('https://ql.i-lz.cn');
                // }
            }
            return Promise.reject(response);
        }
        return response.data;
    },
    error => {
        if (error.response) {
            console.log('error.response.config.url', error.response);
            if (error.response.config.url.indexOf('logout') > -1) {
                // Notice.error({
                //     title: '系统提示',
                //     desc: '已退出登录',
                //     duration: 4,
                // });
            } else {
                let errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : error.response.data.message;
                switch (error.response.status) {
                    case 404:
                        Notice.error({
                            title: '系统提示',
                            desc: '很抱歉，资源未找到',
                            duration: 4,
                        });
                        break;
                    default:
                        Notice.error({
                            title: '系统提示',
                            desc: errorMessage,
                            duration: 4,
                        });
                        break;
                }
            }
        }
        Promise.reject(error);
    }
);

export default {
    get(url, param) {
        return instance.get(url, param);
    },

    post(url, param) {
        return instance.post(url, param, {
            transformRequest: [
                params => {
                    let result = '';
                    Object.keys(params).forEach(key => {
                        if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
                            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&';
                        }
                    });
                    return result;
                },
            ],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    },

    postJson(url, param) {
        return instance.post(url, param, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    postBody(url, param) {
        return instance.post(url, JSON.stringify(param), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    upload(url, params) {
        return instance.post(url, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};
