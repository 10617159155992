//认证/账户管理
export const loginUrl = '/login/index';
export const loginoutUrl = '/login/logout';
export const userUrl = '/web/default/user';

let webUrl = window.webUrl || '/web/default';

//角色列表
export const groupList = webUrl + '/groupList';

//创建、修改角色
export const saveGroup = webUrl + '/saveGroup';

//修改角色权限
export const saveGroupPower = webUrl + '/saveGroupPower';

//账号列表
export const accountList = webUrl + '/accoutList';

//修改账号
export const saveAccount = webUrl + '/saveAccount';

//创建账号
export const createAccout = webUrl + '/createAccout';

//用户列表
export const userList = webUrl + '/userList';

//服务列表
export const serveList = webUrl + '/serveList';

//修改创建服务
export const saveServe = webUrl + '/saveServe';

//服务详情
export const serveView = webUrl + '/serveView';

//删除服务
export const delServe = webUrl + '/delServe';

//预约订单列表
export const reservationList = webUrl + '/reservationList';

//预约单详情
export const reservationView = webUrl + '/reservationView';

//删除预约单
export const delReservation = webUrl + '/delReservation';

//检测管理 start  ------------------------------------------------------------------------------------

//检测模版列表
export const checkTempList = webUrl + '/checkTempList';

//检测模版详情
export const checkTempView = webUrl + '/checkTempView';

//创建更新模版
export const saveCheckTemp = webUrl + '/saveCheckTemp';

//删除检测模版
export const delCheckTemp = webUrl + '/delCheckTemp';

//检测分类列表
export const checkClassList = webUrl + '/checkClassList';

//创建更新分类
export const saveCheckClass = webUrl + '/saveCheckClass';

//删除检测分类
export const delCheckClass = webUrl + '/delCheckClass';

//检测单列表
export const checkList = webUrl + '/checkList';
//保存
export const subForm = webUrl + '/saveReservation';


//检测单详情
export const checkView = webUrl + '/checkView';
//检测单编辑
export const editForm = webUrl + '/reservationPor';
//发送报告
export const sendReport = webUrl + '/sendReport';

//检测管理 end------------------------------------------------------------------------------------

//Banner列表
export const bannerList = webUrl + '/bannerList';

//创建更新Banner
export const saveBanner = webUrl + '/saveBanner';

//删除Banner
export const delBanner = webUrl + '/delBaner';

//Banner详情
export const bannerView = webUrl + '/bannerView';
// 修改账号状态
export const switchStatus = webUrl + '/saveStatus';

//商品管理-------------------------------------------------------------------------------------------

//商品分类列表
export const goodsListClass = '/web/goods/listClass';
//创建更新分类
export const goodsSaveClass = '/web/goods/saveClass';
//删除分类
export const goodsDelClass = '/web/goods/delClass';

//添加规格
export const saveSpecUrl = '/web/goods/saveSpec';
//查询规格
export const searchSpecUrl = '/web/goods/searchSpec';

//商品参数
export const goodsParameterUrl = '/web/goods/parameter';
//添加更新商品参数
export const goodsSaveParameter = '/web/goods/saveParameter';
//删除商品参数
export const goodsDelParameter = '/web/goods/delParameter';

//添加修改商品
export const goodsSaveUrl = '/web/goods/save';
//商品列表
export const goodsListUrl = '/web/goods/index';
//商品详情
export const goodsViewUrl = '/web/goods/View';
//修改商品分类
export const goodsUpdateClassUrl = '/web/goods/updateClass';
//上下架商品
export const goodsSaveStatusUrl = '/web/goods/saveStatus';

//反馈列表
export const feedbackUrl = '/web/other/feedback';
//反馈删除
export const delFeedbackUrl = '/web/other/delFeedback';

//资讯列表
export const newsUrl = '/web/other/news';
//创建更新资讯
export const saveNewsUrl = '/web/other/saveNews';
//删除资讯

export const delNewsUrl = '/web/other/delNews';

//修改账号密码
export const savePwdUrl = '/web/default/savePwd';

//服务商列表
export const merchantUrl = '/web/merchant/index';
//创建服务商账号
export const merchantCreateAccountUrl = '/web/merchant/createAccout';
//修改服务商信息
export const merchantSaveAccountUrl = '/web/merchant/saveAccount';

//分配服务商
export const allocationMUrl = '/web/default/allocationM';
//分配检测人员
export const allocationCUrl = '/web/default/allocationC';

//用户信息详情
export const userViewUrl = '/web/default/userView';

//微信支付设置
export const wxPaySetViewUrl = '/web/other/wxPaySetView';
//微信支付配置
export const wxPaySetUrl = '/web/other/wxPaySet';

//售后原因列表
export const applyInfoListUrl = '/web/order/applyInfoList';
//新建更新售后原因
export const saveApplyInfoUrl = '/web/order/saveApplyInfo';
//删除售后原因
export const delApplyInfoUrl = '/web/order/delApplyInfo';

//商品订单
export const orderIndexUrl = '/web/order/index';
//订单详情
export const orderViewUrl = '/web/order/view';
//物流信息
export const orderSfcListUrl = '/web/order/sfcList';
//物流服务商
export const orderSfcModUrl = '/web/order/sfcMod';
//订单发货
export const orderSfcUrl = '/web/order/sfc';
//通用设置配置
export const orderSettingUrl = '/web/order/setting';
//通用设置配置保存
export const orderSaveSettingUrl = '/web/order/saveSetting';
//售后订单
export const orderApplyListUrl = '/web/order/applyList';
//售后审核退款
export const applyCheckRefundUrl = '/web/order/applyCheckRefund';
//退款订单
export const orderRefundListUrl = '/web/order/refundList';
//退款记录分析
export const orderRefundGoodUrl = '/web/order/refundGood';

//销售统计
export const saleStatUrl = '/web/other/saleStat';
//会员统计
export const userStatUrl = '/web/other/userStat';
//订单统计
export const orderStatUrl = '/web/other/orderStat';

//商品分类属性
export const goodsSerListUrl = '/web/goods/serList';

//首页分类配置列表
export const mappingListUrl = '/web/default/mappingList';
//创建更新首页分类配置
export const saveMappingUrl = '/web/default/saveMapping';
//删除首页分类配置
export const delMappingUrl = '/web/default/delMapping';

//首页广告配置
export const homeGdListUrl = '/web/other/homeGdList';
//创建更新首页广告
export const saveHomeGdUrl = '/web/other/saveHomeGd';
//删除首页广告
export const delHomeGdUrl = '/web/other/delHomeGd';

//优惠码列表
export const discountCodeListUrl = '/web/other/saleActList';
//创建更新优惠码
export const discountCodeSaveUrl = '/web/other/saveSaleAct';
//删除优惠码
export const discountCodeDelUrl = '/web/other/delSaleAct';
//分销列表
export const agentlistUrl = '/web/agent/list';
//新增省代理
export const addAgentUrl = '/web/agent/save';
//编辑省代理
export const updateAgentUrl = '/web/agent/update';
//详情
export const readAgentUrl = '/web/agent/read';
//修改密码
export const modPasswodeUrl = '/web/agent/modPassword';

//删除
export const deleteUrl = '/web/agent/delete';
//暂停资格
export const cancelUrl = '/web/agent/cancel';
//保存快捷应用
export const saveFastappsUrl = '/web/default/saveFastapps';
//获取快捷应用
export const getFastappsUrl = '/web/default/getFastapps';
//订单统计
export const orderUrl = '/web/stat/order';
//经销商统计
export const agentUrl = '/web/stat/agent';
//用户统计
export const userstatUrl = '/web/stat/user';
//问卷调查
export const paperListUrl = '/web/rsq/paperList';
//分销规则设置
export const saveRuleUrl = '/web/agent/saveRule';
//审核
export const processUrl = '/web/agent/process';
//问卷调查保存
export const paperSaveUrl = '/web/rsq/paperSave';
//问卷详情
export const paperReadUrl = '/web/rsq/paperRead';
//手动结束
export const paperEndUrl = '/web/rsq/paperEnd';
//手动开启关闭
export const paperPublishUrl = '/web/rsq/paperPublish';
//测光统计
export const photometryUrl = '/web/stat/photometry';
//企微定时通知
export const massListUrl = '/web/message/massList';
//通知保存
export const massSaveUrl = '/web/message/massSave';

//分销商统计
export const agentStaticUrl = '/web/stat/agent';

// 提现申请列表
export const getWdLogs = '/web/finance/wdLogs';

//财务规则保存
export const saveFinanceRuleUrl = '/web/finance/saveRule';

//获取财务规则
export const getFinanceRuleUrl = '/web/finance/getRule';

//提现审核
export const wdCheckUrl = '/web/finance/wdCheck';
//获取分销规则
export const getRuleUrl = '/web/agent/getRule';
//获取剩余佣金
export const totalFundsUrl = '/web/agent/totalFunds';
//佣金明细
export const incExpLogUrl = '/web/agent/balanceLogs';
//导出
export const incExpLogUrl1 = '/web/agent/balanceLogs';

//导入
export const wdImportUrl = '/web/finance/wdImport';

//导出问卷调查
export const paperReportUrl = '/web/rsq/paperReport';

//企微模板列表
export const subtplListUrl = '/web/message/tplCropList';
//企微保存
export const tplCropSaveUrl = '/web/message/tplCropSave';
//部门列表 
export const departmentListUrl = '/web/message/departmentList';
//模板详情
export const tplCropReadUrl = '/web/message/tplCropRead';
//成员列表
export const simpleListUrl = '/web/message/simpleList';

//企微列表删除
export const tplCropDeleteUrl = '/web/message/tplCropDelete';
//分享次数
export const shareUrl = '/web/stat/share';
//不合格因素
export const abnormalUrl = '/web/stat/abnormal';

//小程序消息订阅
export const tplMpListUrl = '/web/message/tplMpList';
//模板选择列表
export const mpTplListUrl = '/web/message/mpTplList';
//订阅保存
export const tplMpSaveUrl = '/web/message/tplMpSave';
//订阅详情
export const tplMpReadUrl = '/web/message/tplMpRead';
//订阅删除
export const tplMpDeleteUrl = '/web/message/tplMpDelete';
//选择列表
export const pnTplListUrl = '/web/message/pnTplList';
//公众号列表
export const tplPnListUrl = '/web/message/tplPnList';
//公众号保存
export const tplPnSaveUrl = '/web/message/tplPnSave';
//公众号详情
export const tplPnReadUrl = '/web/message/tplPnRead';
//公众号删除
export const tplPnDeleteUrl = '/web/message/tplPnDelete';
//公众号绑定
export const wxAuthUrl = '/web/manage/wxAuth';

































